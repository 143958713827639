<script setup lang="ts">
import { useWindowSize } from "@vueuse/core"
import type { StyleValue } from "vue"

const props = defineProps<{
  content: string
  container?: HTMLElement
  theme: "amber" | "purple"
  left?: boolean
  y: number
}>()
const textContent = ref<HTMLDivElement>()
const { height } = useWindowSize()

function letterOpacityStyle(index: number) {
  if (!props.container)
    return { opacity: 1 }
  const containerTop = props.container.offsetTop

  const containerHeight = props.container.getBoundingClientRect().height + 0.00001
  const scrollFrac = Math.min(1, (props.y - containerTop) / containerHeight)

  const totalCharacters = props.content.length + 0.0001
  const charFrac = 1 - index / totalCharacters

  const totalFrac = clamp(charFrac + scrollFrac * 2.5 - 0.5, 0.25, 1)
  return { opacity: totalFrac }
}

function translateScrollStyle(): StyleValue {
  if (!props.container)
    return { transform: "translate(0px, 0px)" }
  const containerTop = props.container.offsetTop
  const containerHeight = props.container.getBoundingClientRect().height + 0.00001
  const containerBottom = containerTop + containerHeight
  const containerPageY = (containerHeight / 2) - (height.value / 2)
  // Fraction of the section that has scrolled
  const scrollFrac = Math.min(1, (props.y - containerTop) / containerHeight)
  // undo scroll position of object then add container page value to center div
  let centerTransY = (scrollFrac) * containerHeight - containerPageY
  if (containerTop > props.y) { // scroll in
    centerTransY = -containerPageY
    return { transform: `translate3d(0px, ${centerTransY}px, 0px)` }
  }
  else if (containerBottom < (props.y + height.value)) { // scroll out
    centerTransY = containerPageY
    return { transform: `translate3d(0px, ${centerTransY}px, 0px)` }
  }

  const contentHeight = textContent.value?.getBoundingClientRect().height || 0
  return {
    "position": "fixed",
    "top": "50%",
    "margin-top": px(-contentHeight / 2),
    "left": props.left ? "0%" : "50%",
    // transform: `translate3d(0px, ${centerTransY}px, 0px)`
  }
}
// watch(() => props.y, () => {
//   if (!textContent.value)
//     return
//   textContent.value.style.transform = translateScrollStyle().transform
// })
</script>

<template>
  <div
    ref="textContent"
    :style="translateScrollStyle()"
    class="pointer-events-none text-neutral-white"
  >
    <div class="mb-8">
      <div
        class="pointer-events-none inline inline-flex items-center gap-1.5 rounded-lg bg-opacity-50 py-2 pl-2 pr-3.5 text-[20px] font-normal tracking-wide backdrop-blur-lg backdrop-filter"
        :class="{
          'bg-[#321B0083] text-$global-amber-primary': theme === 'amber',
          'bg-[#34194383] text-$global-purple-primary': theme === 'purple',
        }"
      >
        <slot />
      </div>
    </div>
    <span
      v-for="(letter, key) in content"
      :key
      :style="letterOpacityStyle(key)"
    >
      {{ letter }}
    </span>
  </div>
</template>
